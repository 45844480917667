import PropTypes from 'prop-types';
// @mui
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';
// mui icons
import TaskAltOutlinedIcon from '@mui/icons-material/TaskAltOutlined';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
// iconify
import Iconify from '../iconify/iconify';

// ----------------------------------------------------------------------

export default function Items({ title, value, icon, funct, greenText, color }) {
  const theme = useTheme();
  return (
    <Stack direction="row" justifyContent="space-between" alignItems="center" sx={{ mb: 0.5 }}>
      {icon ? (
        <Box
          sx={{
            textAlign: 'left',
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <Iconify icon={icon} width={12} />
          <Typography variant="body1" sx={{ color: 'text.primary', ml: 2 }}>
            {title}
          </Typography>
        </Box>
      ) : title && (
        <Typography variant="body1" sx={{ color: 'text.primary', ml: 2 }}>
          {title}
        </Typography>
      )}
      {funct ? (
        <Box sx={{ textAlign: 'right' }}>
          <Typography variant="subtitle2" sx={{ fontWeight: 600, color: color || 'text.primary' }}>
            {funct(value)}
          </Typography>
        </Box>
      ) : (
        <Box sx={{ textAlign: 'right' }}>
          <Typography variant="subtitle2" sx={{ fontWeight: 600, color: color || 'text.primary' }}>
            {value}
          </Typography>
        </Box>
      )}
    </Stack>
  );
}

Items.propTypes = {
  icon: PropTypes.object,
  title: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  funct: PropTypes.func,
  greenText: PropTypes.bool,
  color: PropTypes.string,
};
