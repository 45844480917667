import { lazy, Suspense } from 'react';
import { Outlet } from 'react-router-dom';
// auth
import { AuthGuard, GuestGuard } from 'src/auth/guard';
// layouts
import AdminConsoleDashboardLayout from 'src/layouts/admin_console';
// components
import { LoadingScreen } from 'src/components/loading-screen';

// ----------------------------------------------------------------------
const IndicatorsConstructorPage = lazy(() => import('src/pages/console_admin/indicators_builder'));
const ConsoleAdminPage = lazy(() => import('src/pages/console_admin/admin'));
const BusinessRulesPage = lazy(() => import('src/pages/console_admin/business_rules'));

const admin_console = {
  path: '',
  element: (
    <AuthGuard>
      <Suspense fallback={<LoadingScreen />}>
        <Outlet />
      </Suspense>
    </AuthGuard>
  ),
  children: [
    {
      element: (
        <AdminConsoleDashboardLayout>
          <IndicatorsConstructorPage />
        </AdminConsoleDashboardLayout>
      ),

      index: true,
    },
    {
      path: 'indicatorsBuilder',
      element: (
        <AdminConsoleDashboardLayout>
          <IndicatorsConstructorPage />
        </AdminConsoleDashboardLayout>
      ),
    },
    {
      path: 'admin',
      element: (
        <AdminConsoleDashboardLayout>
          <ConsoleAdminPage />
        </AdminConsoleDashboardLayout>
      ),
    },
    {
      path: 'businessRules',
      element: (
        <AdminConsoleDashboardLayout>
          <BusinessRulesPage />
        </AdminConsoleDashboardLayout>
      ),
    },
  ],
};

export const adminConsoleRoutes = [
  {
    path: 'console',
    children: [admin_console],
  },
  {
    path: 'admin',
    children: [admin_console],
  },
];
