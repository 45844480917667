import axios from 'axios';
// ----------------------------------------------------------------------
const BASE_API = process.env.REACT_APP_URL_API;

const axiosInstance = axios.create({ baseURL: BASE_API });

axiosInstance.interceptors.response.use(
  (res) => res,
  (error) => Promise.reject(error)
);

export default axiosInstance;

// ----------------------------------------------------------------------

export const fetcher = async (args) => {
  const [url, config] = Array.isArray(args) ? args : [args];

  const res = await axiosInstance.get(url, { ...config });

  return res.data;
};

// ----------------------------------------------------------------------

export const endpoints = {
  chat: 'https://api-dev-minimal-v510.vercel.app/api/chat',
  api: {
    auth: {
      login: `${BASE_API}/auth/login`,
      getUser: `${BASE_API}/auth/user-exists`,
      registration: `${BASE_API}/auth/registration`,
      verificationMail: `${BASE_API}/auth/verification-mail`,
      deleteUser: `${BASE_API}/auth/delete-user`,
      resetPassword: `${BASE_API}/auth/reset-password`,
      test: `${BASE_API}/auth/test`,
    },
    documentUrl: {
      get: `${BASE_API}/documents/download-urls`,
      put: `${BASE_API}/documents/upload-url`,
    },
    document: {
      list: (type, uuid) => `${BASE_API}/documents/list/${type}/${uuid}`,
      delete: (id) => `${BASE_API}/documents/${id}/delete`,
      approve: (id) => `${BASE_API}/documents/${id}/approve`,
      decline: (id) => `${BASE_API}/documents/${id}/decline`,
      upload: (id) => `${BASE_API}/documents/${id}/upload`,
    },
    agent: {
      form: `${BASE_API}/agent/form`,
      editForm: (uuid) => `${BASE_API}/agent/form/${uuid}`,
      list: `${BASE_API}/agent/list`,
      get: (nif) => `${BASE_API}/agent/${nif}`,
    },
    client: {
      root: (id) => `${BASE_API}/clients/${id}`,
      me: `${BASE_API}/clients/me`,
      get_uuid: (uuid) => `${BASE_API}/clients/${uuid}`,
      documents: `${BASE_API}/clients/me/documents`,
      approve_application: `${BASE_API}/clients/approve-application`,
      questions: (id) => `${BASE_API}/clients/me/questions/${id}`,
      answers: `${BASE_API}/clients/me/answers`,
      metrics: `${BASE_API}/clients/metrics`,
      process: `${BASE_API}/clients/process`,
      documentType: `${BASE_API}/clients/document-type`,
      validateSolution: `${BASE_API}/clients/me/validate-solution`,
      list: `${BASE_API}/clients/list`,
      checkout: `${BASE_API}/clients/checkout`,
      unnax: `${BASE_API}/clients/get-unnax-token`,
      addDocument: (id) => `${BASE_API}/clients/${id}/add-document`,
      changeSolution: (id) => `${BASE_API}/clients/${id}/change-solution`,
      availableSolutions: (id) => `${BASE_API}/clients/${id}/available-solutions`,
      availableDocuments: (id) => `${BASE_API}/clients/${id}/available-documents`,
      payment: `${BASE_API}/payment/pay`,
      getContracts: `${BASE_API}/clients/get-contracts`,
      bankAggregation: `${BASE_API}/clients/bank-aggregation`,
      recommendation: `${BASE_API}/clients/recommendation`,
      manualRecommendation: `${BASE_API}/clients/manual-recommendation`,
      changeType: `${BASE_API}/clients/client-type`,
    },
    console: {
      variablesList: `${BASE_API}/console/builder/variables`,
      deleteVariable: `${BASE_API}/console/builder/variables`,
      admin: `${BASE_API}/console/builder/variables`,
      variablesOrder: `${BASE_API}/console/admin/variables`,
      thresholds: `${BASE_API}/console/admin/thresholds`,
      model: `${BASE_API}/console/admin/model`,
      modelFilename: `${BASE_API}/console/admin/model-filename`
    },
    chat: {
      chat: `${BASE_API}/chat/get-chat`,
      sendMessage: `${BASE_API}/chat/send-message`,
      markAsRead: `${BASE_API}/chat/mark-read`,
    },
    catalogs: {
      catalog: `${BASE_API}/catalog/get-catalog`,
      rules: `${BASE_API}/catalog/business-rules`,
      duplicateRules: `${BASE_API}/catalog/duplicate-business-rule`,
      rulesConditions: `${BASE_API}/catalog/business-rules-conditions`,
      rulesStates: `${BASE_API}/catalog/business-rules-states`,
      rulesList: `${BASE_API}/catalog/business-rules-list`,
      rulesFields: `${BASE_API}/catalog/business-rules-fields`,
      rulesCatalogs: `${BASE_API}/catalog/business-rules-catalogs`,
    },
  },
};
