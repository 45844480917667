import { useRef } from 'react';
import PropTypes from 'prop-types';
import { useFormContext, Controller } from 'react-hook-form';
// @mui
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
// Format currency
import NumericFormatCustom from 'src/utils/money-input-format';

// ----------------------------------------------------------------------

export default function RHFTextField({
  name,
  helperText,
  type,
  handleOnChange,
  icon,
  moneyFormat,
  ...other
}) {
  const { control } = useFormContext();
  const inputRef = useRef(null);
  return moneyFormat ? (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <TextField
          {...field}
          fullWidth
          type={type}
          onChange={(event) => {
            if (type === 'number') {
              field.onChange(Number(event.target.value));
            } else {
              field.onChange(event.target.value);
            }
            if (handleOnChange) {
              handleOnChange(event.target.value, name);
            }
          }}
          error={!!error}
          helperText={error ? error?.message : helperText}
          {...other}
          InputProps={{
            startAdornment: icon && <InputAdornment position="start">{icon}</InputAdornment>,
            inputComponent: NumericFormatCustom, // Set directly (caveat)
          }}
          inputProps={{
            ref: inputRef,
          }}
        />
      )}
    />
  ) : (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <TextField
          {...field}
          fullWidth
          type={type}
          onChange={(event) => {
            if (type === 'number') {
              field.onChange(Number(event.target.value));
            } else {
              field.onChange(event.target.value);
            }
            if (handleOnChange) {
              handleOnChange(event.target.value, name);
            }
          }}
          error={!!error}
          helperText={error ? error?.message : helperText}
          {...other}
          InputProps={{
            startAdornment: icon && <InputAdornment position="start">{icon}</InputAdornment>,
          }}
        />
      )}
    />
  );
}

RHFTextField.propTypes = {
  helperText: PropTypes.object,
  name: PropTypes.string,
  type: PropTypes.string,
  handleOnChange: PropTypes.func,
  icon: PropTypes.element,
  moneyFormat: PropTypes.bool,
};
