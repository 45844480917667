import { Navigate, useRoutes } from 'react-router-dom';
// config
import { PATH_AFTER_LOGIN_ADMIN } from 'src/config-global';
//
import { mainRoutes } from './main';

import { authRoutes } from './auth';
import { agentsRoutes } from './agents';
import { adminsRoutes } from './admins';
import { analystsRoutes } from './analysts';
import { clientsRoutes } from './clients';
import { fullScreenRoutes } from './full_screen';
import { adminConsoleRoutes } from './admin_console';

// ----------------------------------------------------------------------

export default function Router() {
  return useRoutes([
    {
      path: '/',
      element: <Navigate to={PATH_AFTER_LOGIN_ADMIN} replace />,
    },

    // Auth routes
    ...authRoutes,

    // Agent Dashboard routes
    ...agentsRoutes,

    // Admin Dashboard routes
    ...adminsRoutes,

    // Analyst Dashboard routes
    ...analystsRoutes,

    // Dashboard routes
    ...clientsRoutes,

    // Admin Full screen routes
    ...fullScreenRoutes,

    // Main routes
    ...mainRoutes,

    // Admin console routes
    ...adminConsoleRoutes,

    // No match 404
    { path: '*', element: <Navigate to="/404" replace /> },
  ]);
}
