/* eslint-disable class-methods-use-this */
import axios, { endpoints } from 'src/utils/axios';
import { setSession } from 'src/auth/context/utils';

const interceptorCorrect = (response) => response;
// eslint-disable-next-line consistent-return
const interceptorError = async (error) => {
  console.log('ERROOOOOOOR', error);
  if (error.response.status === 402 || error.response.status === 401) {
    sessionStorage.setItem('redirected', true);
    sessionStorage.setItem('redirectMessage', error.response.data.error);
    sessionStorage.setItem('redirectSeverity', 'error');
    console.log('ERROR 401');
    await setSession();
    /* eslint-disable no-restricted-globals */
    location.reload();
  } else if (error.response.status === 501) {
    console.log('ERROR 501');
  } else {
    return Promise.reject(error);
  }
};

class ClientService {
  static axiosInstance;

  static createAxios = () => {
    ClientService.axiosInstance = axios.create({
      withCredentials: true,
      defaults: {
        headers: axios.defaults.headers,
      },
    });

    ClientService.axiosInstance.interceptors.response.use(interceptorCorrect, interceptorError);

    return ClientService.axiosInstance;
  };

  static async get(uuid) {
    const axiosInstance = this.createAxios();
    const endpoint = endpoints.api.client.get_uuid(uuid);
    const response = await axiosInstance.get(endpoint);

    return response.data;
  }

  static async me() {
    const axiosInstance = this.createAxios();
    const endpoint = endpoints.api.client.me;
    const response = await axiosInstance.get(endpoint);

    return response.data;
  }

  static async process(uuid, process) {
    const axiosInstance = this.createAxios();
    console.log('Process endpoint front:', uuid, process);
    const endpoint = endpoints.api.client.process;
    // const response = await axiosInstance.put(endpoint, data);
    const response = await axiosInstance.put(endpoint, uuid, process);

    return response.data;
  }

  static async documentType(uuid, process) {
    const axiosInstance = this.createAxios();
    const endpoint = endpoints.api.client.documentType;
    // const response = await axiosInstance.put(endpoint, data);
    const response = await axiosInstance.put(endpoint, uuid, process);

    return response.data;
  }

  static async changeType(uuid, type) {
    const axiosInstance = this.createAxios();
    const endpoint = endpoints.api.client.changeType;
    const response = await axiosInstance.post(endpoint, { uuid, type });

    return response.data;
  }

  static async approve_application(uuid) {
    const axiosInstance = this.createAxios();
    const endpoint = endpoints.api.client.approve_application;
    const response = await axiosInstance.post(endpoint, { uuid });

    return response.data;
  }

  static async documents() {
    const axiosInstance = this.createAxios();
    const endpoint = endpoints.api.client.documents;
    const response = await axiosInstance.get(endpoint);

    return response.data;
  }

  static async validateSolution() {
    const axiosInstance = this.createAxios();
    const endpoint = endpoints.api.client.validateSolution;
    const response = await axiosInstance.get(endpoint);

    return response.data;
  }

  static async questions(id) {
    const axiosInstance = this.createAxios();
    const endpoint = endpoints.api.client.questions(id);
    const response = await axiosInstance.get(endpoint);

    return response.data;
  }

  static async answers(uuid, answers) {
    const axiosInstance = this.createAxios();
    console.log('Datos en answers font', answers); // Aquí no llega pero al back si
    const endpoint = endpoints.api.client.answers;
    const response = await axiosInstance.post(endpoint, uuid, answers);

    return response.data;
  }

  static async updateProfile(data) {
    const axiosInstance = this.createAxios();
    const endpoint = endpoints.api.client.profile;
    const response = await axiosInstance.put(endpoint, data);

    return response.data;
  }

  static async updateTelephones(data) {
    const axiosInstance = this.createAxios();
    const endpoint = endpoints.api.client.telephones;
    const response = await axiosInstance.put(endpoint, data);

    return response.data;
  }

  static async changePassword(data) {
    const axiosInstance = this.createAxios();
    const endpoint = endpoints.api.client.changePassword;
    const response = await axiosInstance.put(endpoint, data);

    return response.data;
  }

  static async availableSolutions(userId) {
    const axiosInstance = this.createAxios();
    const endpoint = endpoints.api.client.availableSolutions(userId);
    const response = await axiosInstance.get(endpoint);

    return response.data;
  }

  static async availableDocuments(userId) {
    const axiosInstance = this.createAxios();
    const endpoint = endpoints.api.client.availableDocuments(userId);
    const response = await axiosInstance.get(endpoint);

    return response.data;
  }

  static async changeSolution(userId, newSolution) {
    const axiosInstance = this.createAxios();
    const endpoint = endpoints.api.client.changeSolution(userId);
    const response = await axiosInstance.put(endpoint, newSolution);

    return response.data;
  }

  static async changeRecommendation(uuid, recommendation) {
    const axiosInstance = this.createAxios();
    const endpoint = endpoints.api.client.recommendation;

    const response = await axiosInstance.post(endpoint, {
      uuid,
      recommendation,
    });

    return response.data;
  }

  static async changeRecommendationManual(uuid, previousRecommendation, recommendation, email) {
    const axiosInstance = this.createAxios();
    const endpoint = endpoints.api.client.manualRecommendation;
    console.log(
      `Se manda a ${endpoint} estos datos: ${previousRecommendation}, ${recommendation}, ${email}`
    );
    const response = await axiosInstance.post(endpoint, {
      uuid,
      previousRecommendation,
      recommendation,
      email,
    });

    return response.data;
  }

  static async addDocument(userId, newDocument) {
    const axiosInstance = this.createAxios();
    const endpoint = endpoints.api.client.addDocument(userId);
    const response = await axiosInstance.post(endpoint, {
      document: newDocument,
    });

    return response.data;
  }

  static async list(prms) {
    const axiosInstance = this.createAxios();
    const endpoint = endpoints.api.client.list;
    console.log('Lista filtros:', prms);
    const response = await axiosInstance.get(endpoint, prms);

    return response.data;
  }

  static async metrics() {
    const axiosInstance = this.createAxios();
    const endpoint = endpoints.api.client.metrics;
    const response = await axiosInstance.get(endpoint);

    return response.data;
  }

  static async getUnnaxToken() {
    const axiosInstance = this.createAxios();
    const endpoint = endpoints.api.client.unnax;
    const response = await axiosInstance.get(endpoint);

    return response.data;
  }

  static async getUnnax(endpoint, prms, headers) {
    const axiosInstance = this.createAxios();
    const response = await axiosInstance.get(endpoint, prms, { headers });
    return response.data;
  }

  static async getInfoToken() {
    const axiosInstance = this.createAxios();
    const endpoint = endpoints.api.client.getInfoToken;
    const response = await axiosInstance.get(endpoint);

    return response.data;
  }

  static async payment(data) {
    const axiosInstance = this.createAxios();
    const endpoint = endpoints.api.client.payment;
    const response = await axiosInstance.post(endpoint, data);

    return response.data;
  }

  static async getContracts() {
    const axiosInstance = this.createAxios();
    const endpoint = endpoints.api.client.getContracts;
    const response = await axiosInstance.post(endpoint);

    return response.data;
  }

  static async bankAggregation() {
    const axiosInstance = this.createAxios();
    const endpoint = endpoints.api.client.bankAggregation;
    const response = await axiosInstance.get(endpoint);

    return response.data;
  }
}

export default ClientService;
